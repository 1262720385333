import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import maximac from "../images/maxima-c.jpg";

const MaximaC = () => {
  return (
    <div>
        <Header/>
        <div class="container text-center">
        <h2 id="title">MAXIMA CARGO</h2>
    </div>
    <section id="details">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 text-center wow zoomIn">
                    <img src={maximac} alt="maxima-c" class="details-img"/>
                </div>
                <div class="col-lg-6">
                    <p class="details-text">The world’s largest selling 3 wheeler is now available in cargo with a Powerful diesel Engine. The vehicle is highly durable with long lasting dry clutch, uniaxial balancer for less vibrations & reinforced chassis for longer vehicle
                        life. It has car Like advanced Features like car type Clutch pedal to handle heavy cargo better, 5 speed gear box to handle tough roads better, wide seat for driver comfort. Maxima cargo can bear heavy load and yet is very low
                        on maintenance.</p>
                </div>
            </div>
            <div class="container text-center">
                <h4>Specification</h4>
            </div>
            <div class="row">
                <table class="table table-borderless table-striped">
                    <tbody>
                        <tr>
                            <td>Engine Type</td>
                            <td>4 Stroke Engine</td>
                        </tr>
                        <tr>
                            <td>Cubic Capacity(cc)</td>
                            <td>470.5cc</td>
                        </tr>
                        <tr>
                            <td>Transmission</td>
                            <td>5 Forward + 1 Reverse Gear</td>
                        </tr>
                        <tr>
                            <td>Fuel Type</td>
                            <td>Diesel</td>
                        </tr>
                        <tr>
                            <td>Battery</td>
                            <td>12V</td>
                        </tr>
                        <tr>
                            <td>Weight</td>
                            <td>528Kg</td>
                        </tr>
                        <tr>
                            <td>Max Speed</td>
                            <td>50Kmph</td>
                        </tr>
                        <tr>
                            <td>Passenger Capacity</td>
                            <td>1D + 1</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
        <Footer/>
    </div>
  )
}

export default MaximaC