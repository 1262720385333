import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/OscarHeader'
import OscarProduct from '../components/OscarProduct'
import oscar from "../images/oscar.jpeg"
import logo from "../images/logo-white.png"
import jade from "../images/Jade.jpg";
import gold from "../images/OIP.jpg";
import bermel from "../images/DRUM.jpg";
import atf from "../images/Oscar-ATF-III.png";
import zircon from "../images/Oscar-Zircon-Starlight-SAE-15W40.png";
import hydra from "../images/Hydra-XP-HVI-32.png";
import one from "../images/1.jpg";
import two from "../images/2.jpg";
import three from "../images/3.jpg";
import four from "../images/4.jpg";
import five from "../images/5.jpg";
import six from "../images/6.jpg";
import seven from "../images/7.jpg";
import eight from "../images/8.jpg";

const Oscar = () => {
  return (
    <div>
        <Header />
        <img src={oscar} className="background" />
        <div class="container text-center">
            <h2 id="title">OSCAR LUBRICANTS</h2>
            <div className="row">
                <OscarProduct img={jade} text="Oscar Jade Gold Plus SAE 5W30"/>
                <OscarProduct img={gold} text="Oscar Jade Cobalt SAE 20W50"/>
                <OscarProduct img={bermel} text="Oscar GEO 15W40"/>
            </div>
            <div className="row">
                <OscarProduct img={zircon} text="Oscar Zircon Starlight SAE 15W40"/>
                <OscarProduct img={hydra} text="Oscar HydraXP HVI 32"/>
                <OscarProduct img={atf} text="Oscar ATF III"/>
            </div>
        </div>
        <div className="backg my-4">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 my-4">
                    <img src={logo} className="oscar-logo-img" />
                </div>
                <div className="col-lg-6">
                    <p>Oscar Lubricants was established in 1998 with the primary focus of manufacturing and marketing of specialty lubricants, hydraulic brake fluids and automotive radiator coolants. In 2005 the company started production of automotive, industrial and marine lubricants. In 2010 we operated the first extension by doubling the plant plot area and adding more blending capacity.</p>
                </div>
            </div>
        </div>
        </div>
        <h2 className='text-center' id="title">CERTIFICATES</h2>
        <div className="container">
            <div className="row text-center">
                <div className="col-md"><img src={one}/></div>
                <div className="col-md"><img src={two}/></div>
                <div className="col-md"><img src={three}/></div>
                <div className="col-md"><img src={four}/></div>
                <div className="col-md"><img src={five}/></div>
                <div className="col-md"><img src={six}/></div>
                <div className="col-md"><img src={seven}/></div>
                <div className="col-md"><img src={eight}/></div>
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default Oscar