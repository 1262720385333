import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import pulsar from "../images/pulsar-150.jpg";

const Pulsar150 = () => {
  return (
    <div>
        <Header />
        <div class="container text-center">
        <h2 id="title">PULSAR 150cc</h2>
    </div>
    <section id="details">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 text-center wow zoomIn">
                    <img src={pulsar} alt="Pulsar 150cc" class="details-img"/>
                </div>
                <div class="col-lg-6">
                    <p class="details-text">A bike that asserts every rider’s passion for biking by bringing all the features in this single thrill machine. Pulsar 150 is meant for the rider who seeks adventure every day.</p>
                </div>
            </div>
            <div class="container text-center">
                <h4>Specification</h4>
            </div>
            <div class="row">
                <table class="table table-borderless table-striped">
                    <tbody>
                        <tr>
                            <td>Engine Type</td>
                            <td>4 Stroke Engine</td>
                        </tr>
                        <tr>
                            <td>Cubic Capacity(cc)</td>
                            <td>149cc</td>
                        </tr>
                        <tr>
                            <td>Transmission</td>
                            <td>Manual</td>
                        </tr>
                        <tr>
                            <td>Fuel Type</td>
                            <td>Petrol</td>
                        </tr>
                        <tr>
                            <td>Battery</td>
                            <td>12V</td>
                        </tr>
                        <tr>
                            <td>Weight</td>
                            <td>143Kg</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>

        <Footer />
    </div>
  )
}

export default Pulsar150