import React from 'react'
import { Link } from 'react-router-dom'

const Product = ({img, text, link}) => {
  return (
    <div className="col-md-4">
    <div class="wow zoomIn">
        <div class="card">
            <img class="card-img-top" src={img} alt={text}/>
            <div class="card-body">
                <h5 class="card-text">{text}</h5>
                <Link class="product-link" to={link}>More Details</Link>
            </div>
        </div>
    </div> 
    </div>
  )
}

export default Product