import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import re from "../images/bajaj3.jpg";

const Re = () => {
  return (
    <div>
        <Header/>
        <div class="container text-center">
        <h2 id="title">RE COMPACT</h2>
    </div>
    <section id="details">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 text-center wow zoomIn">
                    <img src={re} alt="RE Compact" class="details-img"/>
                </div>
                <div class="col-lg-6">
                    <p class="details-text">Bajaj RE 4S is the leading model for the RE portfolio. It’s an ideal mix of practicality coupled with load ability. Its highly compact and easy to park, maneuver on the roads yet can carry three passengers and extra load without breaking
                        a sweat.</p>
                </div>
            </div>
            <div class="container text-center">
                <h4>Specification</h4>
            </div>
            <div class="row">
                <table class="table table-borderless table-striped">
                    <tbody>
                        <tr>
                            <td>Engine Type</td>
                            <td>4 Stroke Engine</td>
                        </tr>
                        <tr>
                            <td>Cubic Capacity(cc)</td>
                            <td>198.88cc</td>
                        </tr>
                        <tr>
                            <td>Transmission</td>
                            <td>4 Forward + 1 Reverse Gear</td>
                        </tr>
                        <tr>
                            <td>Fuel Type</td>
                            <td>Diesel</td>
                        </tr>
                        <tr>
                            <td>Battery</td>
                            <td>12V</td>
                        </tr>
                        <tr>
                            <td>Weight</td>
                            <td>348Kg</td>
                        </tr>
                        <tr>
                            <td>Clutch</td>
                            <td>Wet multididc type</td>
                        </tr>
                        <tr>
                            <td>Passenger Capacity</td>
                            <td>1D + 3</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>

        <Footer/>
    </div>
  )
}

export default Re