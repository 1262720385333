import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import maximaz from "../images/maxima-z-2.jpg";

const MaximaZ = () => {
  return (
    <div>
        <Header/>
        <div class="container text-center">
        <h2 id="title">MAXIMA-Z</h2>
    </div>
    <section id="details">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 text-center wow zoomIn">
                    <img src={maximaz} alt="Maxima-z" class="details-img"/>
                </div>
                <div class="col-lg-6">
                    <p class="details-text">The Maxima Z is the Bajaj’s ultimate last-mile affordable people carrier three-wheeler. The Maxima Z passenger carriers take forward the reliability, trust and quite lower cost to operate for the entrepreneurs who used this vehicle
                        for his business. Maxima Z has all the key performance characteristics such as power, pickup, higher fuel-mileage, modern stylish and an overall promising 3 wheeler to enhance your business.</p>
                </div>
            </div>
            <div class="container text-center">
                <h4>Specification</h4>
            </div>

            <div class="row">
                <table class="table table-borderless table-striped">
                    <tbody>
                        <tr>
                            <td>Engine Type</td>
                            <td>4 Stroke Engine</td>
                        </tr>
                        <tr>
                            <td>Cubic Capacity(cc)</td>
                            <td>470.5cc</td>
                        </tr>
                        <tr>
                            <td>Transmission</td>
                            <td>5 Forward + 1 Reverse Gear</td>
                        </tr>
                        <tr>
                            <td>Fuel Type</td>
                            <td>Diesel</td>
                        </tr>
                        <tr>
                            <td>Battery</td>
                            <td>12V</td>
                        </tr>
                        <tr>
                            <td>Weight</td>
                            <td>790Kg</td>
                        </tr>
                        <tr>
                            <td>Fuel tank capacity</td>
                            <td>8L</td>
                        </tr>
                        <tr>
                            <td>Passenger Capacity</td>
                            <td>1D + 3</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>

        <Footer/>
    </div>
  )
}

export default MaximaZ