import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import maximaz from "../images/OIP.png";
import image1 from "../images/FB_IMG_16046691071122362.jpg";
import image2 from "../images/FB_IMG_16046697852096609.jpg";
import image3 from "../images/FB_IMG_16046698142076393.jpg";
import image4 from "../images/FB_IMG_16046698665751719.jpg";
import { Link } from 'react-router-dom';
import Product from '../components/Product';
import boxer100 from "../images/boxer.jpg";
import pulsar200 from "../images/pulsar-200.jpg";
import pulsar220 from "../images/pulsar-220.jpg";
import maximaz2 from "../images/maxima-z-2.jpg";
import re from "../images/bajaj3.jpg";
import qute from "../images/qute.jpg";
import jade from "../images/Jade.jpg";
import gold from "../images/OIP.jpg";
import bermel from "../images/DRUM.jpg";
import OscarProduct from '../components/OscarProduct';

const Home = () => {
  return (
    <div>
        <Header />
        <section id="banner">
            <div class="container">
                <div class="row">
                    <div class="col-sm-6 wow fadeInLeft text-center">
                        <span className="notify-badge">NEW</span>
                        <img src={maximaz} alt="three wheelers" class="banner-img" />
                    </div>
                    <div class="col-sm-6 px-5">
                        <p class="head">JTM Trading</p>
                        <p class="head-text">We are happy to announce that JTM Trading is the sole distributor of <b>Oscar Lubricants</b> in Ethiopia. JTM Trading is also one of the largest distributors of the MAXIMA-Z, Bajaj Re Compact, The quadricycle Qute, The Powerful Maxima, The Cargo Three Wheeler Maxima-C, Boxer, Pulsar and Discover.</p>
                        <a href="#products" class="head-link">More Details</a>
                    </div>
                </div>
            </div>
        </section>
        
        <section id="services">
            <div class="container text-center">
                <h2 id="title">SERVICES</h2>
                <div class="row">
                    <div class="col-md-6 wow fadeInUp">
                        <i class="fas fa-motorcycle service-icon"></i>
                        <h3>SALES</h3>
                        <p>Visit our store and choose your favorite motorcycle from variety of options. our store includes three and four wheelers too.</p>
                    </div>
                    <div class="col-md-6 wow fadeInUp">
                        <i class="fas fa-tools service-icon"></i>
                        <h3>SPAREPARTS</h3>
                        <p>Our company also provides a handful of options of spareparts for motorcycles, three and for wheelers.</p>
                    </div>
                </div>
            </div>
        </section>

        <section id="products">
        <div class="container text-center">
            <h2 id="title">OSCAR LUBRICANTS</h2>
            <div className="row">
                <OscarProduct img={jade} text="Oscar Jade Gold Plus SAE 5W30"/>
                <OscarProduct img={gold} text="Oscar Jade Cobalt SAE 20W50"/>
                <OscarProduct img={bermel} text="Oscar GEO 15W40"/>
            </div>
            <Link type="button" class="button-class" to="/oscar"><b>VIEW ALL</b></Link>
            <h2 id="title">PRODUCTS</h2>
            <div class="row">
                <Product img={boxer100} text="Boxer 100" link="/products/boxer100"/>
                <Product img={pulsar200} text="Pulsar 200" link="/products/pulsar200"/>
                <Product img={pulsar220} text="Pulsar 220" link="/products/pulsar220"/>
            </div>
            <div class="row">
                <Product img={maximaz2} text="Maxima Z" link="/products/maxima-z"/>
                <Product img={re} text="RE Compact" link="/products/re-compact"/>
                <Product img={qute} text="Qute" link="/products/qute"/>
            </div>
            <Link type="button" class="button-class" to="/products"><b>VIEW ALL</b></Link>
        </div>
    </section>
    <section id="store">
        <div class="container text-center">
            <h2 id="title">OUR STORE</h2>
            <div class="row">
                <div class="col-md-6 wow zoomIn">
                    <img src={image1} class="store-img bottom-margin" alt="store picture"/>
                </div>
                <div class="col-md-6 wow zoomIn">
                    <img src={image2} class="store-img bottom-margin" alt="store picture"/>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 wow zoomIn">
                    <img src={image3} class="store-img top-margin" alt="store picture"/>
                </div>
                <div class="col-md-6 wow zoomIn">
                    <img src={image4} class="store-img top-margin" alt="store picture"/>
                </div>
            </div>
        </div>
    </section>
    <section id="address">
        <div class="container address wow fadeInDown">
            <div class="location text-center">
                <h2 id="title">LOCATION</h2>
                <p><i class="fas fa-map-marker-alt"></i> Main Office: Kirkos Shop center C-007 & C-009, Addis Ababa, Ethiopia</p>
                <p><i class="fas fa-map-marker-alt"></i> Bethel around CBE Bethel Mariam Branch, Addis Ababa, Ethiopia</p>
            </div>
        </div>
        <div class="gmap_canvas">
            <iframe id="gmap_canvas" src="https://maps.google.com/maps?q=kirkos%20shopping%20center&t=&z=15&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0">
                </iframe>
        </div>
    </section>
        <Footer />
    </div>
  )
}

export default Home