import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import pulsar from "../images/pulsar-220.jpg";

const Pulsar220 = () => {
  return (
    <div>
        <Header/>
        <div class="container text-center">
        <h2 id="title">PULSAR 220cc</h2>
    </div>
    <section id="details">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 text-center wow zoomIn">
                    <img src={pulsar} alt="Pulsar 220cc" class="details-img"/>
                </div>
                <div class="col-lg-6">
                    <p class="details-text">Not just a bike but a beast that has more power than you ever imagine. A creature that arrives swiftly and never goes unnoticed on any road. Have you spotted it yet? This could be your chance to tame it.</p>
                </div>
            </div>
            <div class="container text-center">
                <h4>Specification</h4>
            </div>
            <div class="row">
                <table class="table table-borderless table-striped">
                    <tbody>
                        <tr>
                            <td>Engine Type</td>
                            <td>4 Stroke Engine</td>
                        </tr>
                        <tr>
                            <td>Cubic Capacity(cc)</td>
                            <td>220cc</td>
                        </tr>
                        <tr>
                            <td>Transmission</td>
                            <td>Manual</td>
                        </tr>
                        <tr>
                            <td>Fuel Type</td>
                            <td>Petrol</td>
                        </tr>
                        <tr>
                            <td>Battery</td>
                            <td>12V</td>
                        </tr>
                        <tr>
                            <td>Weight</td>
                            <td>155Kg</td>
                        </tr>
                        <tr>
                            <td>Max Speed</td>
                            <td>134Kmph</td>
                        </tr>
                        <tr>
                            <td>Mileage</td>
                            <td>38Kmpl</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
        <Footer/>
    </div>
  )
}

export default Pulsar220