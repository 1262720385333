import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import discover from "../images/discover-150.jpg";

const Discover150 = () => {
  return (
    <div>
        <Header/>
        <div class="container text-center">
        <h2 id="title">DISCOVER 150cc</h2>
    </div>
    <section id="details">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 text-center wow zoomIn">
                    <img src={discover} alt="Discover 150cc" class="details-img"/>
                </div>
                <div class="col-lg-6">
                    <p class="details-text">Discover 150 is an affirmation in the belief that power and mileage can coexist. It is a bike that combines technologies to deliver a powerful and economical ride. A bike that blends design along with engineering to give you what you
                        desire the most from your vehicle: an unadulterated & exciting ride.</p>
                </div>
            </div>
            <div class="container text-center">
                <h4>Specification</h4>
            </div>
            <div class="row">
                <table class="table table-borderless table-striped">
                    <tbody>
                        <tr>
                            <td>Engine Type</td>
                            <td>4 Stroke Engine</td>
                        </tr>
                        <tr>
                            <td>Cubic Capacity(cc)</td>
                            <td>145cc</td>
                        </tr>
                        <tr>
                            <td>Transmission</td>
                            <td>Manual</td>
                        </tr>
                        <tr>
                            <td>Fuel Type</td>
                            <td>Petrol</td>
                        </tr>
                        <tr>
                            <td>Battery</td>
                            <td>12V</td>
                        </tr>
                        <tr>
                            <td>Weight</td>
                            <td>121Kg</td>
                        </tr>
                        <tr>
                            <td>Max Speed</td>
                            <td>102Kmph</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>

        <Footer/>
    </div>
  )
}

export default Discover150