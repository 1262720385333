import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import Product from '../components/Product'
import boxer100 from "../images/boxer.jpg";
import boxer150 from "../images/boxer2.jpg";
import pulsar150 from "../images/pulsar-150.jpg";
import pulsar180 from "../images/pulsar-180.jpg";
import pulsar200 from "../images/pulsar-200.jpg";
import pulsar220 from "../images/pulsar-220.jpg";
import discover125 from "../images/discover-125.jpg";
import discover150 from "../images/discover-150.jpg";
import maximaz2 from "../images/maxima-z-2.jpg";
import re from "../images/bajaj3.jpg";
import qute from "../images/qute.jpg";
import maxima from "../images/maxima.jpg";
import maximac from "../images/maxima-c.jpg";
import iveco from "../images/daily.png";

const Products = () => {
  return (
    <div>
      <Header />
      <div class="container text-center">
        <h2 className="title mt-5">MOTORCYCLES</h2>
        <div className="row">
          <Product img={boxer100} text="Boxer 100" link="/products/boxer100"/>
          <Product img={boxer150} text="Boxer 150" link="/products/boxer150"/>
          <Product img={pulsar150} text="Pulsar 150" link="/products/pulsar150"/>
        </div>
        <div className="row">
          <Product img={pulsar180} text="Pulsar 180" link="/products/pulsar180"/>
          <Product img={pulsar200} text="Pulsar 200" link="/products/pulsar200"/>
          <Product img={pulsar220} text="Pulsar 220" link="/products/pulsar220"/>
        </div>
        <div className="row">
          <Product img={discover125} text="Discover 125" link="/products/discover125"/>
          <Product img={discover150} text="Discover 150" link="/products/discover150"/>
        </div>
        <h2 className="title mt-4">INTRACITY</h2>
        <div className="row">
          <Product img={maximaz2} text="Maxima Z" link="/products/maxima-z"/>
          <Product img={re} text="RE Compact" link="/products/re-compact"/>
          <Product img={qute} text="Qute" link="/products/qute"/>
        </div>
        <div className="row mb-4">
          <Product img={maxima} text="Maxima" link="/products/maxima"/>
          <Product img={maximac} text="Maxima-C" link="/products/maxima-c"/>
          <Product img={iveco} text="Iveco Daily" link="/products/iveco"/>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Products