import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import daily from "../images/daily.png";

const Daily = () => {
  return (
    <div>
        <Header />
        <div class="container text-center">
        <h2 id="title">IVECO DAILY</h2>
    </div>
    <section id="details">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 text-center wow zoomIn">
                    <img src={daily} alt="Iveco Daily" class="details-img"/>
                </div>
                <div class="col-lg-6">
                    <p class="details-text">Daily cab chassis models allow the owner to fit the body that best suits their needs. The Daily cab chassis provides all the great benefits of the van, including large payload, superior strength, reliability and abundant driver comfort
                        and safety features.</p>
                </div>
            </div>
            <div class="container text-center">
                <h4>Specification</h4>
            </div>
            <div class="row">
                <table class="table table-borderless table-striped">
                    <tbody>
                        <tr>
                            <td>Engine Type</td>
                            <td>4 Stroke Engine</td>
                        </tr>
                        <tr>
                            <td>Transmission</td>
                            <td>Manual</td>
                        </tr>
                        <tr>
                            <td>Fuel Type</td>
                            <td>Diesel</td>
                        </tr>
                        <tr>
                            <td>Battery</td>
                            <td>12V</td>
                        </tr>
                        <tr>
                            <td>Weight</td>
                            <td>3500Kg</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>

        <Footer />
    </div>
  )
}

export default Daily