import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import maxima from "../images/maxima.jpg";

const Maxima = () => {
  return (
    <div>
        <Header/>
        <div class="container text-center">
        <h2 id="title">MAXIMA</h2>
    </div>
    <section id="details">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 text-center wow zoomIn">
                    <img src={maxima} alt="maxima" class="details-img"/>
                </div>
                <div class="col-lg-6">
                    <p class="details-text">The world’s largest selling 3 wheeler is now in diesel. It has the Most Powerful Engine in the Industry with 12% more POWER and 17% more Pickup than competition. The vehicle is highly durable with long lasting dry clutch, uniaxial
                        balancer for less vibrations & reinforced chassis for longer vehicle life. It has car Like advanced Features like car type Clutch pedal to handle heavy passenger better, 5 speed gear box to handle tough roads better, wide seats
                        for Passenger comfort. RE Maxima can bear greater load than the rest of its competition and yet is very low on maintenance.</p>
                </div>
            </div>
            <div class="container text-center">
                <h4>Specification</h4>
            </div>
            <div class="row">
                <table class="table table-borderless table-striped">
                    <tbody>
                        <tr>
                            <td>Engine Type</td>
                            <td>4 Stroke Engine</td>
                        </tr>
                        <tr>
                            <td>Cubic Capacity(cc)</td>
                            <td>447.3cc</td>
                        </tr>
                        <tr>
                            <td>Transmission</td>
                            <td>5 Forward + 1 Reverse Gear</td>
                        </tr>
                        <tr>
                            <td>Fuel Type</td>
                            <td>Diesel</td>
                        </tr>
                        <tr>
                            <td>Battery</td>
                            <td>12V</td>
                        </tr>
                        <tr>
                            <td>Weight</td>
                            <td>505Kg</td>
                        </tr>
                        <tr>
                            <td>Max Speed</td>
                            <td>52Kmph</td>
                        </tr>
                        <tr>
                            <td>Passenger Capacity</td>
                            <td>1D + 3</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>

        <Footer/>
    </div>
  )
}

export default Maxima