import React from 'react';
import ExportsHeader from "../components/ExportsHeader";
import export2 from "../images/export.jpg";

const Exports = () => {
  return (
    <div>
      <ExportsHeader />
      <div class="container">
            <div class="row">
                <div class="col-lg-6 mt-5 wow fadeInRight">
                  <p className='head'>JTM EXPORT</p>
                <p className='head-text'>Our company JTM Trading, not only sells and distributes Three wheelers, Two wheelers and spareparts but it also Exports some goods for foreign markets. Our company brings foreign currencies to help the shoratge in the country. Some of the goods we export include Kabuli Chickpea, Deasi Chickpea, Red Kidney beans, Red Speckled Kidney beans, Light Speckled Kidney Beans and others.</p>
                </div>
                <div class="col-lg-6 text-center wow fadeInLeft">
                    <img src={export2} alt="logo" class="logo-img"/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Exports