import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import pulsar from "../images/pulsar-180.jpg";

const Pulsar180 = () => {
  return (
    <div>
        <Header/>
        <div class="container text-center">
        <h2 id="title">PULSAR 180cc</h2>
    </div>
    <section id="details">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 text-center wow zoomIn">
                    <img src={pulsar} alt="Pulsar 180cc" class="details-img"/>
                </div>
                <div class="col-lg-6">
                    <p class="details-text">Pulsar 180 has been designed to surge ahead while proving its technological acumen. A renowned bike that has been a part of the Pulsar stable since its inception in 2001. It has been consistently winning immense appreciation for its
                        solid, muscular looks and power packed performance.</p>
                </div>
            </div>
            <div class="container text-center">
                <h4>Specification</h4>
            </div>
            <div class="row">
                <table class="table table-borderless table-striped">
                    <tbody>
                        <tr>
                            <td>Engine Type</td>
                            <td>4 Stroke Engine</td>
                        </tr>
                        <tr>
                            <td>Cubic Capacity(cc)</td>
                            <td>178cc</td>
                        </tr>
                        <tr>
                            <td>Transmission</td>
                            <td>Manual</td>
                        </tr>
                        <tr>
                            <td>Fuel Type</td>
                            <td>Petrol</td>
                        </tr>
                        <tr>
                            <td>Battery</td>
                            <td>12V</td>
                        </tr>
                        <tr>
                            <td>Weight</td>
                            <td>147Kg</td>
                        </tr>
                        <tr>
                            <td>Max Speed</td>
                            <td>122Kmph</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>

        <Footer/>
    </div>
  )
}

export default Pulsar180