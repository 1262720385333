import React from 'react'

const Footer = () => {
  return (
    <section id="footer">
        <div class="container">
            <div class="row">
                <div class="col-md-4">
                    <h4>JTM Trading</h4>
                    <p>Come and visit our store located in Addis Ababa, Ethiopia and choose your favorite motorcycle, Three wheelers or Qute.</p>
                    <p class="icons"><a href="https://www.facebook.com/jtmbajaj/" rel="noreferrer"><i class="fab fa-facebook-square"></i></a> <a href="https://m.me/jtmbajaj" rel="noreferrer"><i class="fab fa-facebook-messenger"></i></a></p>
                </div>
                <div class="col-md-4">
                    <h4>Contact #1</h4>
                    <p><i className="fas fa-map-marker-alt"></i> Kirkos Shop center C-007 & C-009,<br/> Addis Ababa, Ethiopia</p>
                    <p><i class="fas fa-mobile-alt"></i> +251 924 87 87 87</p>
                    <p><i className="fas fa-mobile-alt"></i> +251 931 87 87 87</p>
                    <p><i class="fas fa-mobile-alt"></i> +251 911 72 35 58</p>
                    <p><i class="far fa-envelope"></i><a href="mailto:jemalbajaj1@gmail.com" class="footer-link"> jemalbajaj1@gmail.com</a></p>
                </div>
                <div class="col-md-4">
                    <h4>Contact #2</h4>
                    <p><i className="fas fa-map-marker-alt"></i> Bethel 40 meter in front of fire station,<br/> Addis Ababa, Ethiopia</p>
                    <p><i class="fas fa-mobile-alt"></i> +251 911 22 35 80</p>
                    
                    <p><i className="fas fa-fax"></i> +251114703154</p>
                </div>
            </div>
            <hr/>
            <p class="text-center footer-text">Copyright &copy; 2018-2022 JTM Trading. All rights reserved</p>
        </div>
    </section>

  )
}

export default Footer