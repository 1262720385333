import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import logo from "../images/jtm4.png";
import work from "../images/work.jpg";

const About = () => {
  return (
    <div>
      <Header />
      <section id="about">
        <h2 id="title" class="text-center">ABOUT US</h2>
        <div class="container">
            <div class="row">
                <div class="col-lg-6 text-center wow fadeInLeft">
                    <img src={logo} alt="logo" class="logo-img"/>
                </div>
                <div class="col-lg-6 wow fadeInRight">
                    <p>We at <b>JTM TRADING</b>, We believe in excellence and delivering quality products to our customers. We recently begin distributing Oscar Lubricants in Ethiopia. Our many years of work experience make us one of the largest competitors in the Three Wheelers and Motorcycles market in Ethiopia.
                        We are one of the largest distributors of Bajaj Re Compact, the quadricycle Qute, the Powerful Maxima, the Cargo Three Wheeler Maxima-C, Boxer, Pulsar and Discover. We have other three branches in the country located at
                        Bahir dar, Dangila and Merawi in addition to our branches in Addis Ababa. We are proud to serve you at any time, Come and Contact us at any of our branches.</p>
                </div>
            </div>
        </div>
        <div class="container text-center">
            <h2 id="title">OUR TEAM</h2>
        </div>
        <div class="container text-center wow zoomIn">
            <img src={work} class="about-img" alt="people working together"/>
        </div>
        <div class="container">
            <h4 class="text-center">Head Quarters Team</h4>
            <div class="row">
            <div class="col-md-4 wow fadeInUp">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title pl-3">Semira Ahmed</h5>
                            <p class="card-text"><i class="fas fa-mobile-alt"></i> +251 924 87 87 87</p>
                        </div>
                    </div>
                </div>
            <div class="col-md-4 wow fadeInUp">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title pl-3">Hanan Hussen</h5>
                            <p class="card-text"><i class="fas fa-mobile-alt"></i> +251 931 87 87 87</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 wow fadeInUp">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title pl-3">Mubarek Jemal</h5>
                            <p class="card-text"><i class="fas fa-mobile-alt"></i> +251 911 72 35 58</p>
                        </div>
                    </div>
                </div>                
            </div>
            <h4 class="text-center">Bethel Branch Team</h4>
            <div class="row">
            <div class="col-md-4 wow fadeInUp">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title pl-3">Semira G.</h5>
                            <p class="card-text"> <i class="fas fa-mobile-alt"></i> +251 911 22 35 80</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
      <Footer />
    </div>
  )
}

export default About