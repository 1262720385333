import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import boxer from "../images/boxer2.jpg";

const boxer150 = () => {
  return (
    <div>
        <Header />
        <div class="container text-center">
        <h2 id="title">BOXER 150cc</h2>
    </div>
    <section id="details">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 text-center wow zoomIn">
                    <img src={boxer} alt="Boxer 150cc" class="details-img"/>
                </div>
                <div class="col-lg-6">
                    <p class="details-text">Boxer 150 is a great combination of power and fuel efficieny that makes it an ideal bike for city travel as well as for longer rides and across terrain. It is a motorcycle that delivers power on all load and speed conditions.</p>
                </div>
            </div>
            <div class="container text-center">
                <h4>Specification</h4>
            </div>
            <div class="row">
                <table class="table table-borderless table-striped">
                    <tbody>
                        <tr>
                            <td>Engine Type</td>
                            <td>4 Stroke Engine</td>
                        </tr>
                        <tr>
                            <td>Cubic Capacity(cc)</td>
                            <td>144.82cc</td>
                        </tr>
                        <tr>
                            <td>Transmission</td>
                            <td>Manual</td>
                        </tr>
                        <tr>
                            <td>Fuel Type</td>
                            <td>Petrol</td>
                        </tr>
                        <tr>
                            <td>Battery</td>
                            <td>12V</td>
                        </tr>
                        <tr>
                            <td>Weight</td>
                            <td>123Kg</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>

        <Footer />
    </div>
  )
}

export default boxer150