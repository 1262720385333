import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'

const Contact = () => {
  return (
    <div>
      <Header />
      <section id="contact">
        <div class="container">
            <form id="contact-form" method="POST" action="contact-handler.php" encType="multipart/form-data">
                <h2 id="title" class="mt-5 text-center">LEAVE A COMMENT</h2>

                <div class="row text-left">

                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="usr">Full Name:</label><br/>
                            <input name="name" type="text" class="form-control" id="usr" required/>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="pwd">Email:</label><br/>
                            <input name="email" type="email" class="form-control" id="pwd" required/>
                        </div>
                    </div>
                </div>
                <div class="row text-left">
                    <div class="col">
                        <div class="form-group">
                            <label for="comment">Comment:</label><br/>
                            <textarea name="message" class="form-control" rows="10" id="comment" required></textarea>
                        </div>
                    </div>
                </div>
                <div className="text-center">
                <button type="submit" name="submit" class="button-class"><b>SEND MESSAGE</b></button>
                </div>
            </form>
        </div>
    </section>

      <Footer />
    </div>
  )
}

export default Contact