import React from 'react'
import {Link, NavLink} from "react-router-dom";
import logo from "../images/icons/jtm.png"

const Header = () => {
  return (
    <section id="header">
        <div class="container">
            <div className="row">
            <nav class="navbar navbar-expand-lg navbar-light">
                <Link class="navbar-brand" to="/"><img src={logo} alt="Logo"/></Link>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <i class="fa fa-bars"></i>
                </button>
                    <div class="collapse navbar-collapse" id="navbarNav">
                        <ul class="navbar-nav ml-auto">
                            <li class="nav-item">
                                <NavLink class="nav-link" exact to="/">HOME</NavLink>
                            </li>
                            <li class="nav-item">
                                <NavLink class="nav-link" exact to="/about">ABOUT US</NavLink>
                            </li>
                            <li class="nav-item">
                                <NavLink class="nav-link" to="/products">PRODUCTS</NavLink>
                            </li>
                            <li class="nav-item">
                                <NavLink class="nav-link" to="/exports">EXPORTS</NavLink>
                            </li>
                            <li class="nav-item">
                                <NavLink class="nav-link" to="/oscar">OSCAR</NavLink>
                            </li>
                            <li class="nav-item">
                                <NavLink class="nav-link" to="/contact">CONTACT US</NavLink>
                            </li>
                        </ul>
                    </div>
            </nav>
        </div>
        </div>
    </section>
  )
}

export default Header