import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Products from "./pages/Products";
import Exports from "./pages/Exports";
import Contact from "./pages/Contact";
import Oscar from "./pages/Oscar";
import Boxer100 from "./pages/boxer100";
import Boxer150 from "./pages/boxer150";
import Daily from "./pages/Daily";
import Discover125 from "./pages/Discover125";
import Discover150 from "./pages/Discover150";
import Maxima from "./pages/Maxima";
import MaximaC from "./pages/MaximaC";
import MaximaZ from "./pages/MaximaZ";
import Pulsar150 from "./pages/Pulsar150";
import Pulsar180 from "./pages/Pulsar180";
import Pulsar200 from "./pages/Pulsar200";
import Pulsar220 from "./pages/Pulsar220";
import Qute from "./pages/Qute";
import Re from "./pages/Re";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="about" element={<About />} />
        <Route exact path="products" element={<Products />} />
        <Route exact path="exports" element={<Exports />} />
        <Route exact path="oscar" element={<Oscar />} />
        <Route exact path="contact" element={<Contact />} />
        <Route exact path="products/boxer100" element={<Boxer100/>} />
        <Route exact path="products/boxer150" element={<Boxer150/>} />
        <Route exact path="products/iveco" element={<Daily/>} />
        <Route exact path="products/discover125" element={<Discover125/>} />
        <Route exact path="products/discover150" element={<Discover150/>} />
        <Route exact path="products/maxima" element={<Maxima/>} />
        <Route exact path="products/maxima-c" element={<MaximaC/>} />
        <Route exact path="products/maxima-z" element={<MaximaZ/>} />
        <Route exact path="products/pulsar150" element={<Pulsar150/>} />
        <Route exact path="products/pulsar180" element={<Pulsar180/>} />
        <Route exact path="products/pulsar200" element={<Pulsar200/>} />
        <Route exact path="products/pulsar220" element={<Pulsar220/>} />
        <Route exact path="products/qute" element={<Qute/>} />
        <Route exact path="products/re-compact" element={<Re/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
