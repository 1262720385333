import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import qute from "../images/qute.jpg";

const Qute = () => {
  return (
    <div>
        <Header/>
        <div class="container text-center">
        <h2 id="title">QUTE</h2>
    </div>
    <section id="details">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 text-center wow zoomIn">
                    <img src={qute} alt="Qute" class="details-img"/>
                </div>
                <div class="col-lg-6">
                    <p class="details-text">Qute is an eco-friendly, fuel efficient, and economically competitive vehicle for first and last mile transportation. What’s more, it’s a safe and affordable mode of transport for the commuter and provides employment for drivers and
                        fleet owners.</p>
                </div>
            </div>
            <div class="container text-center">
                <h4>Specification</h4>
            </div>
            <div class="row">
                <table class="table table-borderless table-striped">
                    <tbody>
                        <tr>
                            <td>Engine Type</td>
                            <td>4 Stroke Engine</td>
                        </tr>
                        <tr>
                            <td>Cubic Capacity(cc)</td>
                            <td>216.6cc</td>
                        </tr>
                        <tr>
                            <td>Transmission</td>
                            <td>5 Forward + 1 Reverse Gear</td>
                        </tr>
                        <tr>
                            <td>Fuel Type</td>
                            <td>Petrol</td>
                        </tr>
                        <tr>
                            <td>Battery</td>
                            <td>12V</td>
                        </tr>
                        <tr>
                            <td>Weight</td>
                            <td>399Kg</td>
                        </tr>
                        <tr>
                            <td>Max Speed</td>
                            <td>70Kmph</td>
                        </tr>
                        <tr>
                            <td>Passenger Capacity</td>
                            <td>1D + 3</td>
                        </tr>
                        <tr>
                            <td>Body Type</td>
                            <td>Quadricycle</td>
                        </tr>
                        <tr>
                            <td>Number of Doors</td>
                            <td>4 Dooors</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>

        <Footer/>
    </div>
  )
}

export default Qute